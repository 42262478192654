import React, { useContext } from 'react';

// import clsx from 'clsx';

import Icon from '@components/ui/Icon';
import { PricingComparisonWithChai } from '@components/PricingComparisonWithObjects';
import { getLetterFormatedDate } from '@utils';
import { UserContext } from '@providers/User';

import * as styles from './pricingPlans_list.module.css';

/** Lists pricing plans as clickable buttons
 * @param {Object} props Prop object passed to this component
 * @param {?Boolean} props.hideTitle Whether to not render title. Useful when displaying inside a container where tilte is already displayed
 * @param {()=>{}} props.onPlan1BtnClick Do on plan-1 button click
 * @param {()=>{}} props.onPlan2BtnClick Do on plan-2 button click
 * @param {()=>{}} props.onPlan3BtnClick Do on plan-3 button click
 */
export default function PricingPlans_list({ hideTitle, onPlan1BtnClick, onPlan2BtnClick, onPlan3BtnClick }) {
	const { exp, getPlanExpiry } = useContext(UserContext);
	// console.log(exp);
	// console.log('Is active:', Math.floor(Date.now() / 1000) > Number(exp));
	return (
		<>
			<div className={styles.planSelHdr}>
				{!exp || isNaN(Number(exp)) || exp == 0 ? (
					<span className={`${styles.note} ${styles.noteRed}`}>You do not have any active plan</span>
				) : Math.floor(Date.now() / 1000) > Number(exp) ? (
					<span className={`${styles.note} ${styles.noteRed}`}>
						Your last plan ended on <span>{getLetterFormatedDate(exp, false, true, false)}</span>
					</span>
				) : (
					<span className={`${styles.note} ${styles.noteGreen}`}>
						Your plan is active till <span>{getLetterFormatedDate(exp, false, true, false)}</span>
					</span>
				)}
				{!hideTitle && (
					<h3>
						Choose <span>Plan</span>
					</h3>
				)}
			</div>
			<div className={styles.planSelListC}>
				<button
					className={styles.planSelBtn}
					onClick={() => {
						onPlan1BtnClick && typeof onPlan1BtnClick === 'function' && onPlan1BtnClick();
					}}
				>
					<span>
						<h3>1 Month Plan</h3>
					</span>
					<span className={styles.cp}>99</span>
					<span>
						<Icon icon="chevron-right" />
					</span>
				</button>
				<button
					className={styles.planSelBtn}
					onClick={() => {
						onPlan2BtnClick && typeof onPlan2BtnClick === 'function' && onPlan2BtnClick();
					}}
				>
					<span>
						<h3>6 Months Plan</h3>
						<p>1 month Free included</p>
					</span>
					<span className={styles.cp}>495</span>
					<span>
						<Icon icon="chevron-right" />
					</span>
				</button>
				<button
					className={styles.planSelBtn}
					onClick={() => {
						onPlan3BtnClick && typeof onPlan3BtnClick === 'function' && onPlan3BtnClick();
					}}
				>
					<span>
						<h3>12 Months Plan</h3>
						<p>3 months Free included</p>
					</span>
					<span className={styles.cp}>891</span>
					<span>
						<Icon icon="chevron-right" />
					</span>
				</button>
			</div>
			<PricingComparisonWithChai className={styles.planSelGfx} />
		</>
	);
}

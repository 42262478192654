import React, { createContext, useContext, useEffect, useState } from 'react';

import SubPage from '@containers/SubPage';
// import SignInForm from './SignInForm/index';
import SignInForm from './SignInForm/index';
import MyProfileForm from './MyProfileForm/index';
// import { OneMonthPlanPurchaseConfirmation, SixMonthsPlanPurchaseConfirmation, TwelveMonthsPlanPurchaseConfirmation } from './PlanDetailsConfirmationForm/index';
// import PlanSelectionForm from './PlanSelectionForm/index';
import PricingPlans_list from '@components/PricingPlans/PricingPlans_list';
// import PlanDetailsConfirmationForm from './PlanDetailsConfirmationForm/index';
import PlanDetailsConfirmationDialog from '@components/PricingPlans/PlanDetailsConfirmationDialog';
import AdminMenu from './AdminMenu/index';
import { UserContext } from '@providers/User';

/**
 * Provider for the 'UserFormContext' context. Imported in 'src/containers/Root'
 * @example
 * //consume the context in children components
 * const {activeForm,setActiveForm,isWip,setWip} = useContext(UserFormContext);
 */
export const UserFormContext = createContext({
	activeForm: 'auto',
	/**
	 * Sets the specified form as active. Effectively navigating to that form.
	 * @function
	 * @param {!'auto' | 'myProfile' | 'settings' | 'planSelection' | '1monthPlanBuyConfirmation' | '6monthsPlanBuyConfirmation' | '12monthsPlanBuyConfirmation' } form - Form to show / navigate to
	 */
	setActiveForm: (form) => {}, // eslint-disable-line no-unused-vars
	isWip: false,
	setWip: () => {},
	// isWip: false,
	// setWip: ()=>{},
});

const formsAry = ['myProfile', 'settings', 'planSelection', '1monthPlanBuyConfirmation', '6monthsPlanBuyConfirmation', '12monthsPlanBuyConfirmation'];

//imported in Root
export function UserForm_ContextProvider({ children }) {
	const [activeForm, _setActiveForm] = useState('auto');
	const [isWip, setWip] = useState(false);
	// const formsAry = ['myProfile', 'settings', 'planSelection'];

	/**
	 * Sets the specified form as active. Effectively navigating to that form.
	 * @function setActiveForm
	 * @param {!'auto' | 'myProfile'| 'settings'| 'planSelection'| '1monthPlanBuyConfirmation'| '6monthsPlanBuyConfirmation'| '12monthsPlanBuyConfirmation' } form - Form to show / navigate to
	 */
	const setActiveForm = (form) => {
		if (isWip) return;
		if (form === activeForm) return;
		form = form || 'auto'; // if (!form) return;
		if (!formsAry.includes(form)) {
			form = 'auto';
		}
		_setActiveForm(form);
	};
	return <UserFormContext.Provider value={{ activeForm, setActiveForm, isWip, setWip }}>{children}</UserFormContext.Provider>;
}

/* export default function UserForm({ form }) {
	const [activeForm, setActiveForm] = useState(form || 'auto');
	// const { activeForm, setActiveForm } = useContext(UserFormContext);
	const { isLoggedIn, name, email } = useContext(UserContext);

	const formsAry = ['myProfile', 'settings'];

	const goToForm = (form) => {
		form = form || 'auto'; // if (!form) return;
		if (!formsAry.includes(form)) {
			form = 'auto';
		}
		setActiveForm(form);
	};

	useState(() => {
		formsAry.includes(form) ? setActiveForm(form) : setActiveForm('auto');
		// setActiveForm(form);
	}, [form]);

	return isLoggedIn ? (
		<>
			{formsAry.includes(activeForm) ? (
				<>
					{activeForm === 'myProfile' && (
						<SubPage
							onGoBackClick={() => {
								goToForm('auto');
							}}
						>
							<MyProfileForm
								name={name}
								email={email}
								// notify={notify}
							/>
						</SubPage>
					)}
					{activeForm === 'settings' && (
						<SubPage
							onGoBackClick={() => {
								goToForm('auto');
							}}
						>
							<SettingsForm
								name={name}
								email={email}
								// notify={notify}
							/>
						</SubPage>
					)}
				</>
			) : (
				//default to admin-menu
				<AdminMenu goToForm={goToForm} />
			)}
		</>
	) : (
		<SignInForm />
	);
} */

export default function UserForm({ form, hideDropDown }) {
	const { activeForm, setActiveForm, isWip, setWip } = useContext(UserFormContext);
	// const activeForm = 'auto';
	// const setActiveForm = () => {
	// 	console.log('setActive');
	// };
	const { isLoggedIn, name, email, phone } = useContext(UserContext);

	useEffect(() => {
		setActiveForm(form);
	}, [form]);

	return isLoggedIn ? (
		<>
			{formsAry.includes(activeForm) ? (
				<>
					{activeForm === 'myProfile' && (
						<SubPage
							title="My Profile"
							onGoBackClick={() => {
								setActiveForm('auto');
							}}
						>
							<MyProfileForm
								isWip={isWip}
								setWip={setWip}
								name={name}
								email={email}
								phone={phone}
								// notify={notify}
							/>
						</SubPage>
					)}
					{activeForm === 'settings' && (
						<SubPage
							title="Settings"
							onGoBackClick={() => {
								setActiveForm('auto');
							}}
						>
							<SettingsForm
								name={name}
								email={email}
								// notify={notify}
							/>
						</SubPage>
					)}
					{activeForm === 'planSelection' && (
						<SubPage
							title="Choose Plan"
							onGoBackClick={() => {
								setActiveForm('auto');
							}}
						>
							{/* <PlanSelectionForm goToForm={setActiveForm} /> */}
							<PricingPlans_list
								hideTitle={true}
								onPlan1BtnClick={setActiveForm.bind(this, '1monthPlanBuyConfirmation')}
								onPlan2BtnClick={setActiveForm.bind(this, '6monthsPlanBuyConfirmation')}
								onPlan3BtnClick={setActiveForm.bind(this, '12monthsPlanBuyConfirmation')}
							/>
						</SubPage>
					)}
					{activeForm === '1monthPlanBuyConfirmation' && (
						<SubPage
							title="Confirm 1 Month Plan"
							onGoBackClick={() => {
								setActiveForm('planSelection');
							}}
						>
							{/* <OneMonthPlanPurchaseConfirmation hideDropDown={hideDropDown} /> */}
							{/* <PlanDetailsConfirmationForm plan={1} name={name} phone={phone} isWip={isWip} setWip={setWip} hideDropDown={hideDropDown} /> */}
							<PlanDetailsConfirmationDialog
								plan={1}
								doBeforePaymentProcessInit={setWip.bind(this, true)}
								doAfterPaymentProcessFinished={() => {
									hideDropDown();
									setWip(false);
								}}
							/>
						</SubPage>
					)}
					{activeForm === '6monthsPlanBuyConfirmation' && (
						<SubPage
							title="Confirm 6 Months Plan"
							onGoBackClick={() => {
								setActiveForm('planSelection');
							}}
						>
							{/* <SixMonthsPlanPurchaseConfirmation hideDropDown={hideDropDown} /> */}
							{/* <PlanDetailsConfirmationForm plan={2} name={name} phone={phone} isWip={isWip} setWip={setWip} hideDropDown={hideDropDown} /> */}
							<PlanDetailsConfirmationDialog
								plan={2}
								doBeforePaymentProcessInit={setWip.bind(this, true)}
								doAfterPaymentProcessFinished={() => {
									hideDropDown();
									setWip(false);
								}}
							/>
						</SubPage>
					)}
					{activeForm === '12monthsPlanBuyConfirmation' && (
						<SubPage
							title="Confirm 12 Months Plan"
							onGoBackClick={() => {
								setActiveForm('planSelection');
							}}
						>
							{/* <TwelveMonthsPlanPurchaseConfirmation hideDropDown={hideDropDown} /> */}
							{/* <PlanDetailsConfirmationForm plan={3} name={name} phone={phone} isWip={isWip} setWip={setWip} hideDropDown={hideDropDown} /> */}
							<PlanDetailsConfirmationDialog
								plan={3}
								doBeforePaymentProcessInit={setWip.bind(this, true)}
								doAfterPaymentProcessFinished={() => {
									hideDropDown();
									setWip(false);
								}}
							/>
						</SubPage>
					)}
				</>
			) : (
				//default to admin-menu
				<AdminMenu goToForm={setActiveForm} hideDropDown={hideDropDown} />
			)}
		</>
	) : (
		<SignInForm hideDropDown={hideDropDown} />
	);
}

// function MyProfileForm({ goBack }) {
// 	return (
// 		<ul>
// 			<li>
// 				<button onClick={goBack}>Back</button>
// 			</li>
// 			<li>Profile form here</li>
// 		</ul>
// 	);
// }

function SettingsForm({ goBack }) {
	return (
		<ul>
			<li>
				<button onClick={goBack}>Back</button>
			</li>
			<li>Settings form here</li>
		</ul>
	);
}

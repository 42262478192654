import React, { createContext, useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import clsx from 'clsx';
import Icon from '@components/ui/Icon';
import UserForm from '@components/userControlPanel/UserForm';
// import { UserContext } from '@components/User';
import { UserContext } from '@providers/User';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
// import { ToastNotifierContext } from '@components/ToastNotifier';
import { ToastNotificationContext } from '@providers/ToastNotification';
import * as styles from './userDropdown.module.css';

/**
 * React context for 'UserDropDown' component
 */
export const UserDropDownContext = createContext({
	isDropDownViz: false,

	/** Shows the drop-down */
	show: () => {},

	/** Hides the drop-down */
	hide: () => {},
});

/**
 * Provider for the 'UserDropDownContext' context. Imported in 'src/containers/Root'
 * @example
 * const {show,hide} = useContext(UserDropDownContext);	//consume the context in children components
 */
export function UserDropDown_ContextProvider({ children }) {
	const [isDropDownViz, setIsDropDownViz] = useState(false);
	const { id } = useContext(UserContext);
	const isOnline = useContext(NetworkCheckContext);
	const { notify } = useContext(ToastNotificationContext);

	const show = () => {
		if (!id && !isOnline) {
			notify('Please check your internet connection');
			return;
		}
		setIsDropDownViz(true);
	};
	const hide = () => {
		setIsDropDownViz(false);
	};
	return <UserDropDownContext.Provider value={{ isDropDownViz, show, hide }}>{children}</UserDropDownContext.Provider>;
}

// /**
//  * Provider for the 'UserDropDownContext' context. Imported in 'src/containers/Root'
//  * This variant of the provider implements the navigator back-button press event to close the drop-down
//  * @example
//  * const {show,hide} = useContext(UserDropDownContext);	//consume the context in children components
//  */
/* export function UserDropDown_ContextProvider({ children }) {
	const [isDropDownViz, setIsDropDownViz] = useState(false);
	const { id } = useContext(UserContext);
	const isOnline = useContext(NetworkCheckContext);
	const { notify } = useContext(ToastNotificationContext);

	const _bckBtnClickHndlr = (event) => {
		// event.preventDefault(); //this does not seem to work and by default upon clicking the back button, it's same as executing 'window.history.back();'
		// if (event.state && event.state.dropDownOpened == 1) {	//for some reason, gatsby uses reach-router and the state only has the 'key' prop and not the one passed by us when pushing to state
		// console.log('BACK-BTN PRESSED: going back 1 step');
		// isDropDownViz && window && window.removeEventListener('popstate', _bckBtnClickHndlr);
		setIsDropDownViz(false);
		// }
	};

	const show = () => {
		if (!id && !isOnline) {
			notify('Please check your internet connection');
			return;
		}
		// window && window.addEventListener('popstate', _bckBtnClickHndlr);
		// window && window.history.pushState({ dropDownOpened: true }, ''); //write a new entry, so that we can go back to the state which was while setting this state
		// window && window.history.pushState({}, '');
		// navigate('', { state: { dropDownOpened: 1 } });
		!isDropDownViz && navigate(''); //same as window && window.history.pushState({}, '');
		setIsDropDownViz(true);
	};

	const hide = () => {
		//foll also causes the '_bckBtnClickHndlr()' to fire just like as if pressing the back button
		navigate(-1); //same as -- window && window.history.back();
		// console.log('going back 1 step');

		// window && window.removeEventListener('popstate', _bckBtnClickHndlr);
		setIsDropDownViz(false);
	};

	useEffect(() => {
		window && window.addEventListener('popstate', _bckBtnClickHndlr);
		return () => {
			window && window.removeEventListener('popstate', _bckBtnClickHndlr);
		};
	}, []);
	return <UserDropDownContext.Provider value={{ isDropDownViz, show, hide }}>{children}</UserDropDownContext.Provider>;
} */

//imported in Header component
// /**
//  * React component that renders the custom sign-in button or profile badge when signed-in. Imported in 'src/components/Header' component
//  */
/* export default function UserDropDown() {
	// const [isDropDownViz, setIsDropDownViz] = useState(false);
	const { isDropDownViz, show, hide } = useContext(UserDropDownContext);
	const { isLoggedIn, id, name, pic } = useContext(UserContext);
	return (
		<div className={styles.adminBtnC}>
			<button
				onClick={() => {
					// setIsDropDownViz(!isDropDownViz);
					isDropDownViz ? hide() : show(); //toggle
				}}
			>
				{id ? (
					pic ? (
						<>
							<span className={styles.avtr}>
								<img src={pic} alt={name} />
							</span>
							<span>{name.split(' ')[0]}</span>
						</>
					) : (
						<>
							<Icon icon="user" />
							<span>{name.split(' ')[0]}</span>
						</>
					)
				) : (
					<>
						<Icon icon="log-in" />
						<span>Sign In</span>
					</>
				)}
			</button>
			<div className={clsx(styles.dd, isDropDownViz && styles.visible)}>
				<UserForm hideDropDown={hide} />
			</div>
			<button className={styles.ddBck} onClick={hide}></button>
		</div>
	);
} */

/** React component that renders the custom sign-in button or profile badge when signed-in. Imported in 'src/components/Header' component */
export default function UserDropDown() {
	// const [isDropDownViz, setIsDropDownViz] = useState(false);
	const { isDropDownViz, show, hide } = useContext(UserDropDownContext);
	const { isLoggedIn, id, name, pic } = useContext(UserContext);

	// const _bckBtnClickHndlr = (event) => {
	// 	event.preventDefault(); //this does not seem to work and by default upon clicking the back button, it's same as executing 'window.history.back();'
	// 	// hide();
	// 	if (event.state && event.state.dropDownOpened) {
	// 		hide();
	// 	}
	// };

	// useEffect(() => {
	// 	if (isDropDownViz) {
	// 		window.addEventListener('popstate', _bckBtnClickHndlr);
	// 		window.history.pushState({ dropDownOpened: true }, ''); //write a new entry, so that we can go back to the state which was while setting this state
	// 		// navigate('#');
	// 	}
	// 	return () => {
	// 		window && window.removeEventListener('popstate', _bckBtnClickHndlr);
	// 	};
	// }, [isDropDownViz]);
	return (
		<div className={styles.adminBtnC}>
			<button
				onClick={() => {
					// setIsDropDownViz(!isDropDownViz);
					isDropDownViz ? hide() : show(); //toggle
					// if (isDropDownViz) {
					// 	hide();
					// 	window && window.history.back();
					// 	// navigate(-1);
					// } else {
					// 	show();
					// }
				}}
			>
				{id ? (
					pic ? (
						<>
							<span className={styles.avtr}>
								<img src={pic} alt={name} />
							</span>
							<span>{name.split(' ')[0]}</span>
						</>
					) : (
						<>
							<Icon icon="user" />
							<span>{name.split(' ')[0]}</span>
						</>
					)
				) : (
					<>
						<Icon icon="log-in" />
						<span>Sign In</span>
					</>
				)}
			</button>
			<div className={clsx(styles.dd, isDropDownViz && styles.visible)}>
				<UserForm hideDropDown={hide} />
			</div>
			<button
				className={styles.ddBck}
				onClick={hide}
				// onClick={() => {
				// 	hide();
				// 	window && window.history.back();
				// }}
			></button>
		</div>
	);
}

/*global process*/

//LINK TO RAISE A TICKET WITH PAYU -- https://help.payu.in/raise-ticket

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import Loading from '@components/ui/Loading';

import { ToastNotificationContext } from '@providers/ToastNotification';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { StorageContext } from '@providers/Storage';
// import { AwsCredentialsContext } from '@providers/aws/AwsCredentials';
import { DynamoDbContext } from '@providers/aws/DynamoDB';
import { LambdaContext } from '@providers/aws/Lambda';
import { UserContext } from '@providers/User';
import { UserFormContext } from '@components/userControlPanel/UserForm';

/** React context for PayU Payment Gateway */
export const PaymentGateway_payU_Context = createContext({
	/** Creates a new payment order in our backend. We also generate the hash using sensitive salt in our backend
	 * @param {!1|2|3} plan Payment Plan
	 * @param {?Number} [interimPhone] Phone number (If phone from user profile data saved in backend is not available)
	 * @returns {Promise<?CreateOrderSuccessResponse>} Object containing the Transaction ID and hash
	 * @see {@link CreateOrderSuccessResponse}
	 */
	initPayment: async (plan, interimPhone) => {}, //eslint-disable-line
});

/*======================+
|						|
|		TYPE-DEFS		|
|						|
+======================*/
/**
 * User object constructed after reading data from backend DB
 * @typedef {import('@providers/User').ProfileData} ProfileData
 */

/**
 * Success response object received from 'kaagzi-lambda-payuPg-createOrder' lambda function
 * @typedef {Object} CreateOrderSuccessResponse
 * @property {!1} s - Response status
 * @property {!string} txId - Transaction ID
 * @property {!string} h - Hash
 * @property {!string} t - Order creation timestamp
 */

/**
 * Error response object received from 'kaagzi-lambda-payuPg-createOrder' lambda function
 * @typedef {Object} CreateOrderErrorResponse
 * @property {!0} s - Response status
 * @property {!string} m - Error Message. Present in case above property s === 0
 */

/**
 * PayU payment gateway response.
 * @typedef {Object} PayU_response
 * @property {Object} response - The response object.
 * @property {string} response.mihpayid - Payment ID.
 * @property {string} response.mode - Payment mode.
 * @property {string} response.status - Transaction status.
 * @property {string} response.key - Unique key for the transaction.
 * @property {string} response.txnid - Transaction ID.
 * @property {string} response.amount - Transaction amount.
 * @property {string} response.addedon - Date and time of addition.
 * @property {string} response.productinfo - Product information.
 * @property {string} response.firstname - User's first name.
 * @property {string} response.lastname - User's last name.
 * @property {string} response.address1 - Address line 1.
 * @property {string} response.address2 - Address line 2.
 * @property {string} response.city - City.
 * @property {string} response.state - State.
 * @property {string} response.country - Country.
 * @property {string} response.zipcode - Zip code.
 * @property {string} response.email - User's email.
 * @property {string} response.phone - User's phone number.
 * @property {string} response.udf1 - User defined field 1.
 * @property {string} response.udf2 - User defined field 2.
 * @property {string} response.udf3 - User defined field 3.
 * @property {string} response.udf4 - User defined field 4.
 * @property {string} response.udf5 - User defined field 5.
 * @property {string} response.udf6 - User defined field 6.
 * @property {string} response.udf7 - User defined field 7.
 * @property {string} response.udf8 - User defined field 8.
 * @property {string} response.udf9 - User defined field 9.
 * @property {string} response.udf10 - User defined field 10.
 * @property {string} response.card_token - Card token.
 * @property {string} response.card_no - Masked card number.
 * @property {string} response.field0 - Custom field 0.
 * @property {string} response.field1 - Custom field 1.
 * @property {string} response.field2 - Custom field 2.
 * @property {string} response.field3 - Custom field 3.
 * @property {string} response.field4 - Custom field 4.
 * @property {string} response.field5 - Custom field 5.
 * @property {string} response.field6 - Custom field 6.
 * @property {string} response.field7 - Custom field 7.
 * @property {string} response.field8 - Custom field 8.
 * @property {string} response.field9 - Custom field 9.
 * @property {string} response.payment_source - Payment source.
 * @property {string} response.PG_TYPE - Payment gateway type.
 * @property {string} response.error - Error code.
 * @property {string} response.error_Message - Error message.
 * @property {string} response.cardToken - Card token.
 * @property {number} response.net_amount_debit - Net amount debited.
 * @property {string} response.discount - Discount.
 * @property {string} response.offer_key - Offer key.
 * @property {string} response.offer_availed - Offer availed.
 * @property {string} response.unmappedstatus - Unmapped status.
 * @property {string} response.hash - Transaction hash.
 * @property {string} response.bank_ref_no - Bank reference number.
 * @property {string} response.bank_ref_num - Bank reference number.
 * @property {string} response.bankcode - Bank code.
 * @property {string} response.surl - Success URL.
 * @property {string} response.curl - Cancel URL.
 * @property {string} response.furl - Failure URL.
 * @property {string} response.meCode - Merchant code.
 * @property {string} response.card_hash - Card hash.
 * @property {string} response.txnStatus - Transaction status.
 * @property {string} response.txnMessage - Transaction message.
 */

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
/**
 * Provider component for PayU Payment Gateway.
 * This component is imported in 'src/containers/Root'
 */
export default function PaymentGateway_payU_ContextProvider({ children }) {
	// const [scriptLoaded, setScriptLoaded] = useState(false); //since we only use this to know if script has loaded or not, and not for any UI logic, we can use it as a ref
	const scriptLoaded = useRef(false);
	const [isWip, setIsWip] = useState(false); //this shall be utilized to show a loading / processing curtain over the page
	const [isPaymentPopupOpened, setIsPaymentPopupOpened] = useState(false);

	const { notify } = useContext(ToastNotificationContext);
	const isOnline = useContext(NetworkCheckContext);
	const { storageSet, storageGet, storageClear } = useContext(StorageContext);
	const { dbGetItemData } = useContext(DynamoDbContext);
	const invokeFn = useContext(LambdaContext);
	const { id, isLoggedIn, name, email, phone, readUserProfileFromDB, getPlanExpiry } = useContext(UserContext);
	const { setActiveForm, setWip: setFormWip } = useContext(UserFormContext);

	/** Loads js script on demand
	 * @returns {Promise<boolean>} TRUE on successfull loading of script, FALSE otherwise
	 */
	const _loadScript = () => {
		return new Promise((resolve, reject) => {
			if (scriptLoaded.current) resolve(true); //if script is already loaded, then no need to re download
			if (!isOnline) reject(false);

			try {
				//load script here
				const scriptTag = document.createElement('script');
				// scriptTag.id = 'googleGsiClientScript';
				scriptTag.src = process.env.GATSBY_PAYU_JS_URL; //'https://jssdk-uat.payu.in/bolt/bolt.min.js'; //replace with production url
				scriptTag.async = true;
				scriptTag.defer = true;

				// scriptTag.nonce = nonce;
				scriptTag.onload = () => {
					scriptLoaded.current = true; //setScriptLoaded(true);
					resolve(true);
				};
				scriptTag.onerror = () => {
					scriptLoaded.current = false; //setScriptLoaded(false);
					reject(false);
				};
				document.body.appendChild(scriptTag);
				// return true;
			} catch (err) {
				reject(false);
			}
		});
	};

	/**
	 * Creates a new payment order in our backend. We also generate the hash using sensitive salt in our backend
	 * @param {!1|2|3} plan - Payment Plan
	 * @param {?Number} [interimPhone] - Phone number (If phone from user profile data saved in backend is not available)
	 * @returns {Promise<?CreateOrderSuccessResponse>} Object containing the Transaction ID and hash
	 * @see {@link CreateOrderSuccessResponse}
	 */
	const _createOrder = async (plan, interimPhone) => {
		try {
			if (!isOnline) {
				notify('Network unavailable', 'warn');
				throw new Error('Network Error');
			}
			if (!plan) throw new Error('No plan specified');
			if (typeof plan !== 'number' || ![1, 2, 3].includes(plan)) throw new Error('Invalid plan specified');

			//here check if user has provided phone number
			if (!phone && !interimPhone) {
				notify('Kindly provide your phone number');
				//show phone entry form, or we may ask for phone number in the same form where user clicks the proceed to payment button
				return null;
			}

			/**
			 * @type {CreateOrderSuccessResponse|CreateOrderErrorResponse}
			 * @see {@link CreateOrderSuccessResponse} and {@link CreateOrderErrorResponse}
			 */
			const createOrderResp = await invokeFn('kaagzi-lambda-payuPg-createOrder', {
				pl: plan,
				n: name.split(' ')[0],
				e: email,
				//p: phone ? phone.toString() : interimPhone.toString(),
			});
			if (!createOrderResp) throw new Error('Error creating Payment Order');

			if (!Object.prototype.hasOwnProperty.call(createOrderResp, 's')) throw new Error('Error creating Payment Order'); //here check response status
			if (createOrderResp.s === 0) throw new Error(createOrderResp.m || 'Error creating Payment Order'); //Show error returned by backend
			if (createOrderResp.s !== 1) throw new Error('Error creating Payment Order'); //If still the status isn't okay

			if (!Object.prototype.hasOwnProperty.call(createOrderResp, 'txId')) throw new Error('Error reading transaction data');
			if (!Object.prototype.hasOwnProperty.call(createOrderResp, 'h')) throw new Error('Error reading transaction token data');

			// //here store response in localstorage
			// //NOTE that we do not write to state since anyhow we shall be redirected to payment page. We shall load state from storage once we are redirected back after payment
			// //also try and save the 'plan expiry' epoch before the payment is initiated
			// _writeWipOrderDataToStorage_onlyIfDataHasChanged(createOrderResp.oid, createOrderResp.psid, createOrderResp.t, createOrderResp.x);

			// //here we navigate to payment page
			// const cashfree = await cashFreeLoad({
			// 	mode: 'sandbox', //or production
			// });
			// cashfree.checkout({
			// 	paymentSessionId: createOrderResp.psid,
			// 	// redirectTarget: '_self', //optional (_self or _blank)
			// });

			return createOrderResp;
		} catch (err) {
			// console.log('createOrder():', err.message || err.code || err.name || 'Error creating payment order');
			notify(err.message || 'Error creating payment order', 'err');
			return null;
		}
	};

	/** @type {{current:Number}} Integer ID associated with setTimeout */
	const recheckBackendStatus_timerId_ref = useRef(null);

	/** @type {{current:Number}} Keeping track of number of retries. We may use this number to know when to stop auto retrying (and optionally provide user with button to recheck status manually) */
	const checkBackendStatus_numRetries_ref = useRef(0);

	// /** Action to perform on payment success and after confirming that the plan expiry has been updated in backend DB
	//  * @param {{response:{}}} pgResp PG response object
	//  */
	/* const ___doOnBackendUpdatedAfterPaySuccess = (pgResp) => {
		notify('Payment successful and Plan activated', 'success');
		recheckBackendStatus_timerId_ref.current = null;
		checkBackendStatus_numRetries_ref.current = 0;
		setActiveForm('auto');
		setIsPaymentPopupOpened(false);
		setIsWip(false);
		setFormWip(false);

		// GOOGLE ANALYTICS 'purchase' EVENT
		typeof window !== 'undefined' &&
			window.gtag('event', 'purchase', {
				send_to: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
				currency: 'INR',
				value: Number(pgResp.response.amount),
				transaction_id: pgResp.response.txnid,
				items: [
					{
						item_name: pgResp.response.productinfo,
						price: Number(pgResp.response.amount),
						//quantity: 1,	//this is the default
					},
				],
			});
	}; */

	// /** Action to perform on payment success
	//  * @param {{response:{}}} pgResp PG response object
	//  */
	/* const __doOnPaySuccess = async (pgResp) => {
		notify('Payment successful', 'success');

		// recheckBackendStatus_timerId_ref.current !== null && clearTimeout(recheckBackendStatus_timerId_ref.current);
		recheckBackendStatus_timerId_ref.current = null;

		// const { expiry: lastExpiryDate } = await getPlanExpiry();	//since we store it as user-defined-field when creating order, we read it from there
		const { expiry: updatedExpiryEpoch } = await readUserProfileFromDB(id, true);

		if (pgResp.response.udf2.trim() !== '' && !isNaN(Number(pgResp.response.udf2))) {
			//if there was a last expiry timestamp saved (during the time of initiating the payment),

			//if updated expiry is still same as last expiry, it means the backend DB hasn't been updated with the value yet by PG via webhook
			if (updatedExpiryEpoch * 1000 == Number(pgResp.response.udf2)) {
				//retry reading updated value from backend (note that we retry max 3 times)
				if (checkBackendStatus_numRetries_ref.current < 3) {
					recheckBackendStatus_timerId_ref.current = setTimeout(__doOnPaySuccess.bind(this, pgResp), 4000);
					notify('Checking plan status. Please wait');
				}
			} //else we check if the recently fetched expiry from backend is higher(meaning expiry updated in backend)
			else if (updatedExpiryEpoch && updatedExpiryEpoch * 1000 > Number(pgResp.response.udf2)) {
				// console.log('Plan expiry refreshed');
				___doOnBackendUpdatedAfterPaySuccess(pgResp);
			}
		} else {
			//else if no last expiry existed and this is user's first purchase
			// updatedExpiryEpoch > 0 && console.log('Plan expiry refreshed');
			//if updated expiry is stil 0, it means the backend DB hasn't been updated with the value yet by PG via webhook
			if (updatedExpiryEpoch == 0) {
				//retry reading updated value from backend (note that we retry max 3 times)
				if (checkBackendStatus_numRetries_ref.current < 3) {
					recheckBackendStatus_timerId_ref.current = setTimeout(__doOnPaySuccess.bind(this, pgResp), 4000);
					notify('Checking plan status. Please wait');
				}
			} else if (updatedExpiryEpoch > 0) {
				// console.log('Plan expiry refreshed');
				___doOnBackendUpdatedAfterPaySuccess(pgResp);
			}
		}
		checkBackendStatus_numRetries_ref.current++;
	}; */

	/** Action to perform on payment success
	 * @param {PayU_response} pgResp PG response object
	 */
	const __doOnPaySuccess = async (pgResp) => {
		notify('Payment successful', 'success');
		console.log('PG: Checking if plan has been activated');

		// recheckBackendStatus_timerId_ref.current !== null && clearTimeout(recheckBackendStatus_timerId_ref.current);
		recheckBackendStatus_timerId_ref.current = null;

		const { expiry: lastExpiryDate } = await getPlanExpiry(); //we compar against this last expiry date to chek if newly updated epoch in DB is really greater than this
		const { expiry: updatedExpiryEpoch } = await readUserProfileFromDB(id, true);
		const updatedExpiryDate = new Date(updatedExpiryEpoch * 1000);

		if ((() => (lastExpiryDate !== null && lastExpiryDate instanceof Date ? updatedExpiryDate > lastExpiryDate : updatedExpiryDate > new Date()))()) {
			// ___doOnBackendUpdatedAfterPaySuccess(pgResp);
			console.log('PG: Payment successful and plan activated');
			notify('Payment successful and plan activated', 'success');
			recheckBackendStatus_timerId_ref.current = null;
			checkBackendStatus_numRetries_ref.current = 0;
			setActiveForm('auto');
			setIsPaymentPopupOpened(false);
			setIsWip(false);
			setFormWip(false);

			// GOOGLE ANALYTICS 'purchase' EVENT
			typeof window !== 'undefined' &&
				window.gtag('event', 'purchase', {
					send_to: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
					currency: 'INR',
					value: Number(pgResp.response.amount),
					transaction_id: pgResp.response.txnid,
					items: [
						{
							item_name: pgResp.response.productinfo,
							price: Number(pgResp.response.amount),
							//quantity: 1,	//this is the default
						},
					],
				});
		} else {
			//if retries are pending,
			if (checkBackendStatus_numRetries_ref.current < 3) {
				console.log('PG: Still checking if plan has been activated. Please wait');
				notify('Checking plan status. Please wait');
				recheckBackendStatus_timerId_ref.current !== null && clearTimeout(recheckBackendStatus_timerId_ref.current);
				recheckBackendStatus_timerId_ref.current = setTimeout(__doOnPaySuccess.bind(this, pgResp), 4000);
				checkBackendStatus_numRetries_ref.current++;
			} else {
				console.log('PG: Please re-login to check plan status');
				notify('Please re-login to check plan status');
				recheckBackendStatus_timerId_ref.current = null;
				checkBackendStatus_numRetries_ref.current = 0;
				setActiveForm('auto');
				setIsPaymentPopupOpened(false);
				setIsWip(false);
				setFormWip(false);
			}
		}
	};

	/** PayU PG Response handler
	 * @param {PayU_response} pgResp
	 * @see https://docs.payu.in/docs/integrate-checkout-plus
	 */
	const _handleResponse = (pgResp) => {
		(async () => {
			if (pgResp.response.txnStatus == 'SUCCESS') {
				console.log('PG: Payment successful');
				//here verify payment and check if it has been captured in our backend

				recheckBackendStatus_timerId_ref.current = setTimeout(__doOnPaySuccess.bind(this, pgResp), 2000);
				// await new Promise((resolve) => setTimeout(resolve, 2000)); //wait 2 secs for pg to capture and send webhook to update our DB
			}
			if (pgResp.response.txnStatus == 'FAILED') {
				console.log('PG: Payment failed. Please try again.');
				notify('Payment failed', 'err');
				setActiveForm('auto');
				setIsPaymentPopupOpened(false);
				setIsWip(false);
				setFormWip(false);
			}
			if (pgResp.response.txnStatus == 'CANCEL') {
				console.log('PG: Payment cancelled');
				notify('Payment cancelled', 'err');
				setActiveForm('auto');
				setIsPaymentPopupOpened(false);
				setIsWip(false);
				setFormWip(false);
			}
		})();
	};

	/** Shows the payment modal popup
	 * @param {!1|2|3} plan - Payment Plan
	 * @param {!String|Number} transactionId - Order ID
	 * @param {!String} hash - Hash
	 * @returns {Promise<?boolean>}
	 */
	const _payOrder = async (plan, transactionId, hash) => {
		try {
			setIsWip(true);
			setFormWip(true);
			const isScriptLoaded = await _loadScript();
			if (!isScriptLoaded) throw new Error('Error loading Payment Gateway');
			if (!Object.prototype.hasOwnProperty.call(window, 'bolt')) throw new Error('Error initialising Payment Gateway');

			let priceInInr = 99.0, //91.0, //default to 75 + 14(18% GST) + 2(2% payment gateway fee)
				productInfo = '1 Month Plan';
			switch (plan) {
				// case 1: //1 month plan
				// 	// priceInInr = 91.0; // 75 + 14(18% GST) + 2(2% payment gateway fee)
				// 	priceInInr = 77.0; //75 + 2(2% payment gateway fee)	//GST EXEMPTED
				// 	break;
				case 2: //6 months plan
					// priceInInr = 453.0; // 375 + 68(18% GST) + 10(2% payment gateway fee)
					// priceInInr = 383.0; //375 + 8(2% payment gateway fee)	//GST EXEMPTED
					priceInInr = 495.0;
					productInfo = '6 Months Plan';
					break;
				case 3: //12 months plan
					// priceInInr = 814.0; // 675 + 122(18% GST) + 17(2% payment gateway fee)
					// priceInInr = 689.0; //675 + 14(2% payment gateway fee)	//GST EXEMPTED
					priceInInr = 891.0;
					productInfo = '12 Months Plan';
					break;
				default:
					// priceInInr = 91.0;
					// priceInInr = 77.0; //75 + 2(2% payment gateway fee)	//GST EXEMPTED
					priceInInr = 99.0;
					productInfo = '1 Month Plan';
			}

			setIsPaymentPopupOpened(true);
			const name_parts = name.split(' ');

			const pgData = {
				key: process.env.GATSBY_PAYU_MERCHANT_KEY,
				hash: hash, //IMP NOTE: This hash is generated from each of the paameters that we use in this object,
				txnid: transactionId,
				amount: parseInt(priceInInr),
				firstname: name_parts[0], //name.split(' ')[0],
				email: email,
				phone: phone,
				productinfo: productInfo,
				surl: 'http://kaagzi.in',
				furl: 'http://kaagzi.in/console/payment-transactions',
				// lastname: 'surname',
				udf1: id,
				// udf2: lastExpiryEpochDate !== null ? lastExpiryEpochDate.getTime().toString() : '',	//IMP NOTE: If using this, be sure to include it when generating hash
				// enforce_paymethod: 'creditcard|debitcard|HDFB|AXIB',
				// display_lang: 'Hindi',
				// drop_category: 'creditcard|debitcard',
				pg: 'UPI', //default
				// custom_note: 'You will be charged an extra amount of Rs 100 on this transaction',
			};
			if (name_parts.length > 1) pgData.lastname = name_parts[1];

			// //IMP NOTE: ADDING THE 'UDF2' OR ANY OTHER PARAM ALSO REQUIRES IT TO BE PART OF THE HASH GENERATION (CALCULATED IN THE BACKEND)
			// //we store the epoch in ms of last-plan-expiry date-time so that upon successful payment we can check if the expiry has been updated in our backend too.
			// //if upon successful payment, in case the expiry is yet to be updated in our backend with updated expiry epoch, we wait and re-read the value from backend. (Since the backend database entry is written after PG sends webhook post ater it captures payment successfully)
			// const { expiry: lastExpiryEpochDate } = await getPlanExpiry();
			// if (lastExpiryEpochDate !== null) pgData.udf2 = lastExpiryEpochDate.getTime().toString(); //Math.floor(lastExpiryEpochDate.getTime() / 1000).toString();

			window?.bolt?.launch(pgData, {
				responseHandler: _handleResponse,
				catchException: () => {
					setActiveForm('auto');
					notify('Payment failed! Kindly retry', 'err');
				},
			});

			// GOOGLE ANALYTICS 'begin_checkout' EVENT
			typeof window !== 'undefined' &&
				window.gtag('event', 'begin_checkout', {
					send_to: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
					currency: 'INR',
					value: priceInInr,
					items: [
						{
							item_name: productInfo,
							price: priceInInr,
							//quantity: 1,	//this is the default
						},
					],
				});
			return true;
		} catch (err) {
			setIsWip(false);
			setFormWip(false);
			return null;
		}
	};

	/**
	 * Creates a new payment order in our backend. We also generate the hash using sensitive salt in our backend
	 * @param {!1|2|3} plan - Payment Plan
	 * @param {?Number} [interimPhone] - Phone number (If phone from user profile data saved in backend is not available)
	 * @returns {Promise<?CreateOrderSuccessResponse>} Object containing the Transaction ID and hash
	 * @see {@link CreateOrderSuccessResponse}
	 */
	const initPayment = async (plan, interimPhone) => {
		try {
			if (!isOnline) {
				notify('Kindly check your network connection!', 'err');
				return null;
			}
			if (isWip) {
				notify('Last payment is still in progress!');
				return null;
			}
			const createOrderResp = await _createOrder(plan, interimPhone);
			if (!createOrderResp) return null;

			// setIsWip(true);

			await _payOrder(plan, createOrderResp.txId, createOrderResp.h).finally(() => {
				setIsWip(false);
				setFormWip(false);
			});
			// setIsWip(false);
			return createOrderResp;
		} catch (err) {
			setIsWip(false);
			setFormWip(false);
			return null;
		}
	};

	// // loads script (if it isn't already loaded) when we are back online
	// useEffect(() => {
	// 	if (scriptLoaded.current) return; //if (scriptLoaded) return; //if script is already loaded, then no need to proceed

	// 	//if we are back online
	// 	if (isOnline) {
	// 		//load script here
	// 		const scriptTag = document.createElement('script');
	// 		// scriptTag.id = 'googleGsiClientScript';
	// 		scriptTag.src = process.env.GATSBY_PAYU_JS_URL; //'https://jssdk-uat.payu.in/bolt/bolt.min.js'; //replace with production url
	// 		scriptTag.async = true;
	// 		scriptTag.defer = true;
	// 		// scriptTag.nonce = nonce;
	// 		scriptTag.onload = () => {
	// 			scriptLoaded.current = true; //setScriptLoaded(true);
	// 			//initialize		// NOTE : WE INITIALIZE BELOW BY REGISTERING onGoogleLibraryLoad() fn
	// 			// window?.google?.accounts?.id?.initialize({
	// 			// 	client_id: googleClientId,
	// 			// 	callback: handleSignInCredentialResponse,
	// 			// });
	// 		};
	// 		scriptTag.onerror = () => {
	// 			scriptLoaded.current = false; //setScriptLoaded(false);
	// 		};
	// 		document.body.appendChild(scriptTag);

	// 		//we also return a cleanup fn
	// 		return () => {
	// 			// const scriptTag = document?.getElementById('googleGsiClientScript');
	// 			scriptTag && document.body.removeChild(scriptTag);
	// 		};
	// 	}
	// }, [isOnline]); //runs when network availability status changes

	return (
		<PaymentGateway_payU_Context.Provider value={{ initPayment }}>
			{children}
			{isWip && <Loading note="In progress" isDark={true} coverFullPage={true} />}
		</PaymentGateway_payU_Context.Provider>
	);
}

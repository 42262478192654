import React from 'react';

import { prcCmprC, prcCmprGfx, prcCmprTxt } from './pricingComparisonWithObjects.module.css';

/** React component to display how our product compares to everyday use consumables like Chai
 * @param {Object} prop - React prop passed to this component
 * @param {Boolean} [prop.gfxOnRight=false] Whether to display SVG graphics on the right of text
 * @param {?String} [prop.className=''] Any additional CSS classes if any
 */
export function PricingComparisonWithChai({ gfxOnRight, className }) {
	return (
		<div className={`${prcCmprC}${className && className.trim() !== '' ? ' ' + className : ''}`}>
			{!gfxOnRight && (
				<div className={prcCmprGfx}>
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
						<use
							href="#svgFeatureBlob"
							x="48"
							y="56"
							opacity=".25"
							style={{ transformOrigin: '240px 240px', transform: 'scaleX(1.15) rotate(60deg)' }}
						/>
						<use href="#svgFeatureBlob" x="48" y="56" opacity=".5" style={{ transformOrigin: '240px 240px', transform: 'rotate(15deg)' }} />
						<use href="#svgTea" x="136" y="168" />
					</svg>
				</div>
			)}
			<div className={prcCmprTxt}>
				<span>At approx &#8377; 2 / Day,</span>
				<p>
					Kaagzi costs less than your everyday <b>Chai</b>
				</p>
			</div>
			{gfxOnRight && (
				<div className={prcCmprGfx}>
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
						<use
							href="#svgFeatureBlob"
							x="48"
							y="56"
							opacity=".25"
							style={{ transformOrigin: '240px 240px', transform: 'scaleX(1.15) rotate(60deg)' }}
						/>
						<use href="#svgFeatureBlob" x="48" y="56" opacity=".5" style={{ transformOrigin: '240px 240px', transform: 'rotate(15deg)' }} />
						<use href="#svgTea" x="136" y="168" />
					</svg>
				</div>
			)}
		</div>
	);
}

// /**
//  * React component to display how our product compares to everyday use consumables like cigarette
//  * @param {Object} prop - React prop passed to this component
//  * @param {Boolean} [prop.gfxOnRight=false] - Whether to display SVG graphics on the right of text
//  */
// export function PricingComparisonWithCigarette({ gfxOnRight }) {
// 	return (
// 		<div className={prcCmprC}>
// 			{!gfxOnRight && (
// 				<div className={prcCmprGfx}>
// 					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
// 						<use href="#svgFeatureBlob" x="48" y="56" opacity=".25" style={{transformOrigin: '240px 240px', transform: 'scaleX(1.15) rotate(60deg)'}} />
// 						<use href="#svgFeatureBlob" x="48" y="56" opacity=".5" style={{transformOrigin: '240px 240px', transform: 'rotate(15deg)'}} />
// 						{/* <use href="#svgTea" x="136" y="168" /> */}
// 					</svg>
// 				</div>
// 			)}
// 			<div className={prcCmprTxt}>
// 				<span>At approx &#8377; 2 / Day,</span>
// 				<p>
// 					Kaagzi costs less than your everyday puff of <b>Cigarette</b>
// 				</p>
// 			</div>
// 			{gfxOnRight && (
// 				<div className={prcCmprGfx}>
// 					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
// 						<use href="#svgFeatureBlob" x="48" y="56" opacity=".25" style={{transformOrigin: '240px 240px', transform: 'scaleX(1.15) rotate(60deg)'}} />
// 						<use href="#svgFeatureBlob" x="48" y="56" opacity=".5" style={{transformOrigin: '240px 240px', transform: 'rotate(15deg)'}} />
// 						{/* <use href="#svgTea" x="136" y="168" /> */}
// 					</svg>
// 				</div>
// 			)}
// 		</div>
// 	);
// }

import React, { useContext, useState } from 'react';

import Button, { ButtonListContainer } from '@components/ui/Button';
import TextInput from '@components/ui/TextInput';
import CostSplitDetails from '@components/CostSplitDetails';

import { UserContext } from '@providers/User';
import { ToastNotificationContext } from '@providers/ToastNotification';
// import { PaymentGatewayContext } from '@providers/PaymentGateway';
import { PaymentGateway_payU_Context } from '@providers/PaymentGateway/payu';

import * as styles from './planDetailsConfirmationDialog.module.css';

/** Displays the payment confirmation details for the selected plan.
 * In future this component could be offered as part of PaymentGateway provider itself
 * @param {Object} prop - React prop
 * @param {!(1|2|3)} prop.plan - Payment Plan
 * @param {?()=>{}} [prop.doBeforePaymentProcessInit=()=>{}] Callback function to execute on button click
 * @param {?()=>{}} [prop.doAfterPaymentProcessFinished=()=>{}] Callback function to execute on button click
 */
export default function PlanDetailsConfirmationDialog({ plan, doBeforePaymentProcessInit, doAfterPaymentProcessFinished }) {
	if (![1, 2, 3].includes(plan)) return null;
	let planName, costPrice;
	if (plan == 1) {
		planName = '1 month plan';
		costPrice = 99;
	}
	if (plan == 2) {
		planName = '6 months plan';
		costPrice = 495;
	}
	if (plan == 3) {
		planName = '12 months plan';
		costPrice = 891;
	}
	/* 
	//calc GST @ 18%
	const gst = costPrice * 0.18;

	//calc convenience-fee @ 2%

	// const cnvf = costPrice * 0.02;	 //based off costPrice excluding gst

	//Since of the total customer payment, costprice + gst (what we should get after PG deducts 2%) is 98% and PG convenience fee is rest 2%
	//If (costPrice + GST) is 98%, we calculate how much X would be for 100%
	// 	  X			costPrice + GST
	//	-----	x	---------------
	//	 100			  98
	const cnvf = Number(((((costPrice + gst) * 100) / 98) * 0.02).toFixed(2)); //we get 2% out of that total and then round off to 2 decimals
 */
	const [isWip, setIsWip] = useState(false);
	const { name, phone } = useContext(UserContext);

	const [phoneInputScreen, setPhoneInputScreen] = useState(false);
	const [tempPhone, setTempPhone] = useState(phone);
	const [tempPhoneErr, setTempPhoneErr] = useState('');

	const { notify } = useContext(ToastNotificationContext);
	const { initPayment } = useContext(PaymentGateway_payU_Context);
	//console.log(Intl.NumberFormat('en-IN',{style: "currency",currency: "INR"}).format(125698.245));	//logs ₹1,25,698.25

	const _getPhnErrors = (phn) => {
		if (!phn || phn.trim() == '') return 'Phone is required';
		if (!/^[0-9]{10}$/.test(phn)) return 'Enter valid 10 digit number';
		if (phn.trim().length !== 10) return 'Should be 10 digits long';
		return null;
	};

	return (
		<>
			{!phone && phoneInputScreen ? (
				<ul className={styles.dataInpPane} style={{ padding: '1rem 2rem' }}>
					<li>
						Dear <b>{name.split(' ')[0]}</b>, kindly provide your phone number!
					</li>
					<li>
						<TextInput
							type="number"
							placeholder="10 digit phone number"
							title="Phone"
							icon="phone"
							value={tempPhone}
							onChange={(e) => {
								setTempPhoneErr(_getPhnErrors(e.target.value) || '');
								setTempPhone(e.target.value);
							}}
							error={tempPhoneErr}
						/>
					</li>
				</ul>
			) : (
				<CostSplitDetails productName={planName} amt={costPrice} amtIncludesTaxNFees={false} />
			)}
			<ButtonListContainer className={styles.ctaC}>
				<Button
					variant="primary"
					size="big"
					isWip={isWip}
					wipText="Initiating payment"
					icon="arrow-right"
					isIconOnRight={true}
					onClick={() => {
						//here check if phone is available, if not we ask user to enter phone
						//if phone isn't made available by the IdP (Google, FB, etc)
						if (!phone) {
							//if we have not yet shown the phoneInput screen,
							if (!phoneInputScreen) {
								setPhoneInputScreen(true); //show phone input screen
							} else {
								//here validate phone input
								const phErr = _getPhnErrors(tempPhone);
								if (phErr) {
									setTempPhoneErr(phErr);
									notify('Kindly provide valid phone number', 'warn');
									return;
								}
								//if phone is okay, proceed to payment page
								setIsWip(true);
								//invoke payment gateway process
								// createOrder_andNavigateToPaymentPage(plan, tempPhone).finally(() => {
								// 	setIsWip(false); //we won't get to this since we shall be redirected by then
								// });
								doBeforePaymentProcessInit && typeof doBeforePaymentProcessInit === 'function' && doBeforePaymentProcessInit();
								initPayment(plan, tempPhone).finally(() => {
									doAfterPaymentProcessFinished && typeof doAfterPaymentProcessFinished === 'function' && doAfterPaymentProcessFinished();
									setIsWip(false);
								});
							}
						} else {
							setIsWip(true);
							//invoke payment gateway process
							// createOrder_andNavigateToPaymentPage(plan).finally(() => {
							// 	setIsWip(false); //we won't get to this since we shall be redirected by then
							// });
							doBeforePaymentProcessInit && typeof doBeforePaymentProcessInit === 'function' && doBeforePaymentProcessInit();
							initPayment(plan).finally(() => {
								doAfterPaymentProcessFinished && typeof doAfterPaymentProcessFinished === 'function' && doAfterPaymentProcessFinished();
								setIsWip(false);
							});
						}

						// hideDropDown?.();
					}}
				>
					Proceed to Pay
				</Button>
			</ButtonListContainer>
		</>
	);
}

import React from 'react';
// import { createPortal } from 'react-dom';
// import clsx from 'clsx';
import Modal from '@containers/Modal';
import Button, { ButtonListContainer } from '@components/ui/Button';

import * as styles from './confirmationDialog.module.css';

/**
 * Confirmation dialog presented inside a modal
 * @param {Object} prop - prop passed to this component
 * @param {()=>{}} [prop.doOnShow] - Callback fn to execute when dialog is shown
 * @param {()=>{}} [prop.doOnClose] - Callback fn to execute on dialog close
 * @param {string} [prop.confirmationNote='Do you confirm?'] - confirmation note
 * @param {()=>{}} [prop.doOnCancel] - Callback fn to execute when user does not give confirmation
 * @param {()=>{}} prop.doOnConfirm - Callback fn to execute when user confirms action
 * @param {string} prop.confirmBtnText - Text to be shown on confirm button
 */
export default function ConfirmationDialog({ doOnShow, doOnClose, confirmationNote, doOnCancel, doOnConfirm, confirmBtnText, ...restProps }) {
	return (
		<Modal useCloseBtn={false} disallowManualClosing={true} doOnOpen={doOnShow} doOnClose={doOnClose}>
			{(close) => (
				<div className={styles.cnfrmDlgC} {...restProps}>
					<p>{confirmationNote || 'Do you confirm?'}</p>
					<ButtonListContainer>
						<Button
							variant="primary"
							isDark={true}
							onClick={() => {
								doOnConfirm && typeof doOnConfirm === 'function' && doOnConfirm();
								close();
							}}
						>
							{confirmBtnText || 'Confirm'}
						</Button>
						<Button
							variant="tertiary"
							isDark={true}
							onClick={() => {
								doOnCancel && typeof doOnCancel === 'function' && doOnCancel();
								close();
							}}
						>
							Cancel
						</Button>
					</ButtonListContainer>
				</div>
			)}
		</Modal>
	);
}

/* export default function ConfirmationDialog({ children, useCloseBtn, doOnShow, doOnClose, disallowManualClosing, ...restProps }) {
	const [isViz, setIsViz] = useState(false);

	const _open = () => {
		document && document.body && (document.body.style.overflow = 'hidden');
		setTimeout(() => {
			setIsViz(true);
			doOnShow &&
				typeof doOnShow == 'function' &&
				setTimeout(() => {
					doOnShow();
				}, 200);
		}, 10); //a slight delay of 10ms jst for CSS transition state-change to take effect
	};
	const close = () => {
		setIsViz(false); //this.setState({ isVisible: false });
		setTimeout(() => {
			document && document.body && (document.body.style.overflow = 'visible');
			doOnClose && typeof doOnClose == 'function' && doOnClose();
		}, 200);
	};

	useEffect(() => {
		_open();

		return () => {
			document && document.body && (document.body.style.overflow = 'visible');
		};
	}, []);

	return createPortal(
		<div className={clsx(styles.wrap, isViz && styles.isViz)} {...restProps}>
			{useCloseBtn || disallowManualClosing ? <div className={styles.bg}></div> : <div className={styles.bg} onClick={close}></div>}
			<div className={styles.modal}>
				<main>{typeof children == 'function' ? children(close) : children}</main>
				{useCloseBtn && <span className={styles.xBtn} onClick={close} />}
			</div>
		</div>,
		document.body,
	);
} */
